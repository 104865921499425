import { sharedConfig } from '@dop/shared/constants/sharedConfig';
import { Config } from '@dop/shared/types/config';
import { diepgroenColorTheme } from '@dop/ui-primitives/colorTheme/opDefinitions/diepgroenColorTheme';
import { rootColorThemeOP } from '@dop/ui-primitives/colorTheme/opDefinitions/rootColorThemeOP';
import { violetColorTheme } from '@dop/ui-primitives/colorTheme/opDefinitions/violetColorTheme';

export const config: Config = {
	...sharedConfig,
	project: 'op',
	languageCode: 'nl',
	menuPath: '/menu/',
	searchPath: '/zoeken/',
	searchParamQuery: 'zoekterm',
	colorThemes: {
		root: rootColorThemeOP,
		amendments: violetColorTheme, // color theme voor wetswijzigingen
		regulations: diepgroenColorTheme, // color theme voor wetten-en-regels
		subsidies: rootColorThemeOP, // color theme voor subsidies
	},
	localization: {
		locale: 'nl',
		timeZone: 'Europe/Amsterdam',
	},
	accessibilityCertificate:
		'https://www.accessibility.nl/inspecties/inspectie/site-1118',

	numberFormatting: {
		thousandSeperationFromLength: 4,
		chunkSize: 3,
		thousandsSeparatorGlyph: ' ',
		decimalGlyph: ',',
	},

	gtmCode: 'GTM-MZMTGV',

	nameSeq: 'Frontend NL',
};
